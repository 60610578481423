import s from "./Card.module.css";
import {ReactComponent as Copy} from "../../imgs/copy.svg";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {fetchCardDataTC} from "../../state/DataReducer";
import {AppRootStateType} from "../../state/store";
import {ReactComponent as Mir} from "../../imgs/mir.svg";
import {ReactComponent as Sber} from "../../imgs/sber.svg";
import {ReactComponent as Union} from "../../imgs/UnionPay.svg";
import {ReactComponent as Visa} from "../../imgs/visa.svg";
import {ReactComponent as Maste} from "../../imgs/masterCard.svg";

export const Card = () => {
    const userdata = useSelector((state: AppRootStateType) => state.userData.userData);
    const bankCard = useSelector((state: AppRootStateType) => state.userData.userData?.payment_system);
    const rub_amount = useSelector((state: AppRootStateType) => state.userData.userData?.rub_amount);
    const cardNumber = userdata.card_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()

    const [state, setState] = useState<any>({
            value: userdata?.card_number,
            copied: false,
        }
    )


    const [cardNumberCopied, setCardNumberCopied] = useState<boolean>(false);
    const [rub_AmountCopied, setRub_AmountCopied] = useState<boolean>(false);

    const copyCard = async () => {
        await navigator.clipboard.writeText(cardNumber);
        setTimeout(() => {
            debugger
            setCardNumberCopied(false)
        }, 1200)
        setCardNumberCopied(true)
    }
    const copyRub_Amount = async () => {
        const new_rub_amount = rub_amount.toString()
        await navigator.clipboard.writeText(new_rub_amount);
        setTimeout(() => {
            setRub_AmountCopied(false)
        }, 1200)
        setRub_AmountCopied(true)
    }

    // const tr = 'Mastercard' | 'Visa' | 'МИР' | 'UnionPay' | 'СБЕР'




    return (
        <div className={s.Wrapper}>

            <div className={s.Header}>
                {
                    bankCard === 'МИР' && <Mir className={s.Mir}/>
                }
                {
                    bankCard === 'Visa' && <Visa className={s.Visa}/>
                }
                {
                    bankCard === 'UnionPay' && <Union className={s.Union}/>
                }
                {
                    bankCard === 'СБЕР' && <Sber className={s.Sber}/>
                }
                {
                    bankCard === 'Mastercard' && <Maste className={s.Master}/>
                }
            </div>
            <div className={s.Wr}>
                <p>Номер карты для перевода</p>
                <div className={s.NumberOfCard}>
                    <p>{cardNumber}</p>
                    <Copy className={s.Copy} onClick={copyCard}/>
                    <span className={`${s.CopiedText} ${cardNumberCopied && s.ActiveText}`}>
                        Скопировано
                    </span>
                </div>
            </div>
            <div className={s.Wr}>
                <p>Сумма для перевода</p>
                <div className={s.Amount}>
                    <p>{rub_amount} ₽</p>
                    <Copy className={s.Copy} onClick={copyRub_Amount}/>
                    <span className={`${s.CopiedText2} ${rub_AmountCopied && s.ActiveText}`}>
                        Скопировано
                    </span>
                </div>
            </div>

        </div>
    )
}
