import s from "./UnknownError.module.css"
import {ReactComponent as Logo} from "../../imgs/Logo.svg";
import {ReactComponent as Close} from "../../imgs/close.svg";
import {ReactComponent as RejCard} from "../../imgs/rejCard.svg";

import React from "react";

export const UnknownError = () => {
  return(
      <div className={s.Wrapper}>
          <div className={s.Container}>
              <RejCard className={s.Card}/>
              <h2>Error</h2>
              {/*<button className={s.ReturnBtn}>*/}
              {/*    Вернуться на сайт магазина*/}
              {/*</button>*/}
          </div>
      </div>
  )
}