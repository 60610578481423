import axios from "axios";
import {SupportDataType} from "../types/types";


export const fetchCase = (order_id: string) => {
    let promise = axios.get(process.env.REACT_APP_API_URL + `/api/v1/order/${order_id}`);
    // console.log(promise)
    return promise
}
export const fetchOrderStatus = (order_id: string) => {
    let promise = axios.get(process.env.REACT_APP_API_URL + `/api/v1/order/${order_id}/status`);
    // console.log(promise)
    return promise
}

export const postDeclinedOrderStatus = (order_id: string, status: string) => {
    let promise = axios.post(process.env.REACT_APP_API_URL + `/api/v1/order/${order_id}/status`, {
        status: status
    });
    // console.log(promise)
    return promise
}
//
export const createRequestToOpenDIspute = (initiator: string, orderId:string) => {
    let promise = axios.post(process.env.REACT_APP_API_URL + `/api/v1/ticket`, {
        order_id: orderId,
        initiator: initiator,
    })
    console.log(promise)
    return promise
}


// TODO

export const postFormDara = (order_id: string, data: SupportDataType) => {
    const formData = new FormData();
    formData.append('payment_datetime', data.payment_datetime);
    formData.append('card_number', data.card_number);
    // console.log( data.check_one)
    data.check_one && formData.append('check_one', data.check_one);
    // formData.append('check_two', data.check_two  && formData.append());
    formData.append('check_one_amount', data.check_one_amount.toString());
    formData.append('check_two_amount', data.check_two_amount.toString());
    formData.append('message', data.message);
    let promise = axios.post(process.env.REACT_APP_API_URL + `/api/v1/order/${order_id}/support`, formData);
    // console.log(promise)
    return promise
}
