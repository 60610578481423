import React, {useEffect, useState} from "react";
import {Modal} from "../modal/Modal";
import {RejectedPayment} from "../rejectedPayment/RejectedPayment";

import {TimeIsUp} from "../timeIsUp/TimeIsUp";
import {Receiving} from "../receiving/Receiving";
import {PaymentSuccess} from "../paymentSuccess/PaymentSuccess";
import {Application} from "../application/Application";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {UnknownError} from "../unknownError/UnknownError";
import {SuccessApplication} from "../successApplication/SuccessApplication";
import {fetchCardDataTC} from "../../state/DataReducer";
import {SbpStep1} from "./sbpStep1/SbpStep1";
import {SbpReceiving} from "./sbpreceiving/SbpReceiving";

export const SbpStepWrapper = () => {
    const [returnToForm, setReturnToForm] = useState(false);
    const [canselForm, setCanselPayment] = useState(false);
    const [isCounting, setIsCounting] = useState<boolean>(true);
    const [action, setAction] = useState<'' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "error_dispute" | "error-page" | "rejected">('');
    const {order_id} = useParams<{ order_id: string }>();
    const typeData = useSelector((state: AppRootStateType) => state.userData.userData?.type);
    const dispatch: Function = useDispatch();

    const cardStatus = useSelector((state: AppRootStateType) => state.userData.userData?.status);
    const expired_in = useSelector((state: AppRootStateType) => state.userData.userData?.expired_in);

    const error = useSelector((state: AppRootStateType) => state.userData.error);

    useEffect(() => {
        switch (cardStatus) {
            case 'created':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('')
                break;
            case 'processing':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('nextStep')
                break;
            case 'cancelled':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                if (expired_in > 0) {
                    setAction('user')
                } else {
                    setAction('time')
                }
                break;
            case 'confirmed':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('payment')
                break;
            case 'dispute':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('successSupport')
                break;
            case "auto_dispute":
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('error_dispute')
                break
            case "rejected":
                if(typeData !== 'p2p'){
                    setAction("error-page")
                    return
                }
                setAction('rejected')
                break
        }
    }, [expired_in, typeData, cardStatus]);

    // console.log(action)
    // console.log(cardStatus)

    useEffect(() => {
        dispatch(fetchCardDataTC(order_id || ''))
    }, [order_id, dispatch]);


    const canselPaymentHandler = () => {
        return setReturnToForm(true)
    }
    // console.log(isCounting)
    if (error) {
        console.log(error)
        return <UnknownError/>
    }

    return (
        <>
            {
                !returnToForm ?
                    '' : <Modal setReturnToForm={setReturnToForm}
                                returnToForm={returnToForm}
                                canselForm={canselForm}
                                setCanselPayment={setCanselPayment}
                                action={action}
                                setAction={setAction}/>
            }
            {
                action === 'payment' && <PaymentSuccess/>
            }
            {action === '' && cardStatus === 'created' &&
                // <Application setAction={setAction}/>
                <SbpStep1 setAction={setAction}
                          setIsCounting={setIsCounting}
                          action={action}
                          canselPaymentHandler={canselPaymentHandler}
                          isCounting={isCounting}
                />
            }
            {
                action === 'nextStep' && <SbpReceiving canselPaymentHandler={canselPaymentHandler}
                                                       setIsCounting={setIsCounting}
                                                       isCounting={isCounting}
                                                       setAction={setAction}

                />
            }
            {
                action === 'user' && <RejectedPayment setAction={setAction}/>

            }
            {
                action === 'support' && <Application setAction={setAction}
                />
            }
            {
                action === 'time' && <TimeIsUp setAction={setAction}/>

            }
            {
                action === 'successSupport' && <SuccessApplication/>

            }
            {
                action === 'error_dispute' && <TimeIsUp setAction={setAction}/>
            }

            {
                action === 'error-page' && <UnknownError/>
            }
            {
                action === 'rejected' && <UnknownError/>
            }
        </>
    )
}