import {createRequestToOpenDIspute, fetchCase, fetchOrderStatus, postDeclinedOrderStatus} from "../http/http";
import {OrderDataType} from "../types/types";
import {ThunkAction} from "redux-thunk";
import {AppRootStateType} from "./store";

const initialState = {
    userData: {} as OrderDataType,
    error: null as null | string,
    redirect_url:''
}

type InitialStateType = typeof initialState

export const dataReducer = (state: InitialStateType = initialState, action: ActionsType): InitialStateType => {
    switch (action.type) {
        case 'SET-CARD-DATA':
            return {...state, userData: action.payLoad}
        case 'SET-CARD-ERROR':
            return {...state, error: action.payLoad}
        case 'POST-CARD-STATUS':
            if (!state.userData) {
                return state
            }
            return {...state, userData: {...state.userData, status: action.payLoad}}
        case 'SET-CARD-STATUS':
            if (!state.userData) {
                return state
            }
            return {...state, userData: {...state.userData, status: action.payLoad}}
        case 'POST-DECLINED-CARD-STATUS':
            if (!state.userData) {
                return state
            }
            return {...state, userData: {...state.userData, status: action.payLoad}}
        case 'CREATE-REQUEST-TO-REDIRECT':
            if (!state.userData) {
                return state
            }
            return {...state, userData: {...state.userData, initiator: action.initiator, orderId: action.orderId}, redirect_url: action.redirect_url }
        default:
            return state
    }
}

export const setCardAC = (userData: OrderDataType) => {
    return {type: 'SET-CARD-DATA', payLoad: userData} as const
}

export const setCardErrorAC = (error: string | null) => {
    return {type: 'SET-CARD-ERROR', payLoad: error} as const
}
type statusType = "created" | "cancelled" | "confirmed" | "declined" | "dispute" | "processing"

export const setCardStatusAC = (status: statusType) => {
    return {type: 'SET-CARD-STATUS', payLoad: status} as const
}
export const postCardStatusAC = (status: statusType) => {
    return {type: 'POST-CARD-STATUS', payLoad: status} as const
}
export const postDeclinedCardStatusAC = (status: statusType) => {
    return {type: 'POST-DECLINED-CARD-STATUS', payLoad: status} as const
}
export const createRequestToOpenDisputeAC = (initiator: string, orderId: string, link: string = '') => {
    return {
        type: 'CREATE-REQUEST-TO-REDIRECT', initiator: initiator,
        orderId: orderId,
        redirect_url: link
    } as const
}

type AcType = ReturnType<typeof setCardAC>
type SetCardErrorType = ReturnType<typeof setCardErrorAC>
type SetCardStatusType = ReturnType<typeof setCardStatusAC>
type PostCardStatusType = ReturnType<typeof postCardStatusAC>
type PostDeclinedCardStatusType = ReturnType<typeof postDeclinedCardStatusAC>
type createRequestToOpenDisputeType = ReturnType<typeof createRequestToOpenDisputeAC>

type ActionsType =
    AcType
    | SetCardErrorType
    | SetCardStatusType
    | PostCardStatusType
    | PostDeclinedCardStatusType
    | createRequestToOpenDisputeType

export const fetchCardDataTC = (order_id: string): ThunkAction<Promise<void>, AppRootStateType, any, ActionsType> => (dispatch) => {
    return fetchCase(order_id).then(res => {
        // const data:OrderDataType = {...res.data, expired_in: res.data.expired_in * 1000 + Date.now()}
        // dispatch(setCardAC(data))
        dispatch(setCardAC(res.data))
    }).catch((e) => {
        // console.log(e.message)
        dispatch(setCardErrorAC(e.message))
    })
}
export const fetchCardStatusTC = (order_id: string): ThunkAction<Promise<void>, AppRootStateType, any, ActionsType> => (dispatch) => {
    return fetchOrderStatus(order_id).then(res => {
        dispatch(setCardStatusAC(res.data.status))
    }).catch((e) => {
        dispatch(setCardErrorAC(e.message))
    })
}

export const fetchPostDeclinedCardStatusTC = (order_id: string, status: string): ThunkAction<Promise<void>, AppRootStateType, any, ActionsType> => (dispatch) => {
    return postDeclinedOrderStatus(order_id, status).then(res => {
        dispatch(postDeclinedCardStatusAC(res.data))
    }).catch((e) => {
        // dispatch(setCardErrorAC(e.message))
        dispatch(postDeclinedCardStatusAC(e.message))
    })
}

export const fetchCreateRequestToOpenDisputeTC = (initiator: string, orderId: string): ThunkAction<Promise<void>, AppRootStateType, any, ActionsType> => (dispatch) => {
    return createRequestToOpenDIspute(initiator, orderId).then(res => {
        window.open(res.data.redirect_url, "_self")
        dispatch(createRequestToOpenDisputeAC(initiator, orderId))
    }).catch((e) => {
        dispatch(createRequestToOpenDisputeAC(e.message, e.message))
    })
}
