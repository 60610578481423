import s from "./RejectedPayment.module.css"
import {ReactComponent as Logo} from "../../imgs/Logo.svg";
import {ReactComponent as Close} from "../../imgs/close.svg";
import {ReactComponent as RejCard} from "../../imgs/rejCard.svg";

import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {fetchCreateRequestToOpenDisputeTC, fetchPostDeclinedCardStatusTC} from "../../state/DataReducer";
import {createRequestToOpenDIspute} from "../../http/http";

type RejectType = {
    setAction: (tr: '' | 'time' | 'user' | 'payment' | "support") => void
}

export const RejectedPayment = (props: RejectType) => {
    const param = window.location.href.split('/').at(-1)
    const dispatch: Function = useDispatch();

    const redirectToSupportHandler = () => {
        // dispatch(fetchCreateRequestToOpenDisputeTC("user" || '', param || ''))
        props.setAction('support')
    }

    let linkToSite = useSelector((state: AppRootStateType) => state.userData.redirect_url);
    if (!linkToSite?.startsWith('http')) {
        linkToSite = 'http://' + linkToSite
    }

    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <RejCard className={s.Card}/>
                <h2>Платеж вами отклонен</h2>
                <p className={s.Desc}>Вы отменили платеж</p>
                <button className={s.ReturnBtn}>
                    <a href={linkToSite}>
                        Вернуться на сайт магазина
                    </a>
                </button>
                <button className={s.FailedBtn} onClick={redirectToSupportHandler}>
                    Я перевел, платеж не прошел!
                </button>
            </div>
        </div>
    )
}