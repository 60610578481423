import s from "../additionalSteps/firstAddirionalStep/firstAdditionalStep1.module.css";
import style from "./Sbp.module.css";
import {ReactComponent as Logo} from "../../imgs/Logo.svg"
import React, {ChangeEvent, FocusEvent, useEffect, useState, KeyboardEvent} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";

import {fetchCardDataTC} from "../../state/DataReducer"
import {UnknownError} from "../unknownError/UnknownError";
import {AppRootStateType} from "../../state/store";
import {TimeIsUp} from "../timeIsUp/TimeIsUp";

export const Sbp = () => {
    const dispatch: Function = useDispatch();
    const {order_id} = useParams<{ order_id: string }>();
    const cardStatus = useSelector((state: AppRootStateType) => state.userData.userData?.status);
    const expired_in = useSelector((state: AppRootStateType) => state.userData.userData?.expired_in);
    const navigate = useNavigate();
    const [action, setAction] = useState<'' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "error_dispute" | "error-page" | "rejected">('');
    const typeData = useSelector((state: AppRootStateType) => state.userData.userData?.type);

    useEffect(() => {
        dispatch(fetchCardDataTC(order_id || ''))
    }, [order_id, dispatch]);

    useEffect(() => {
        switch (cardStatus) {
            case 'created':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('')
                break;
            case 'cancelled':
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                if (expired_in > 0) {
                    setAction('user')
                } else {
                    setAction('time')
                }
                break;
            case "auto_dispute":
                if(typeData !== 'sbp'){
                    setAction("error-page")
                    return
                }
                setAction('error_dispute')
                break
            case "rejected":
                if(typeData !== 'p2p'){
                    setAction("error-page")
                    return
                }
                setAction('rejected')
                break
        }
    }, [typeData ,expired_in, cardStatus]);


    const [value, setValue] = useState('');
    const [disableBtn, setDisableBtn] = useState(true);
    const [errorInput, setErrorInput] = useState<boolean | string>(false);

    const error = useSelector((state: AppRootStateType) => state.userData.error);

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = e.currentTarget.value.replace(/[^\d+]/g, '').trim()
        const reg = /(\+7)+([0-9]){10}/gs.test(e.currentTarget.value)
        // console.log(reg)
        let masked_val = e.currentTarget.value.slice(0,2) + " (" + e.currentTarget.value.slice(2,5) + ") " +
            e.currentTarget.value.slice(5,8) + "-" + e.currentTarget.value.slice(8,10) + "-" + e.currentTarget.value.slice(10,12)

        if (e.currentTarget.value.length > 11) {
            e.currentTarget.value = masked_val
        }
        if(reg){
            setDisableBtn(false)
        }else{
            setDisableBtn(true)
        }
        setValue(e.currentTarget.value)
        setErrorInput(false)
    }

    const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
        if(value.length >= 18){
            // console.log('blur - - false')
            setErrorInput(false)
        }else{
            // console.log('blur - - text')
            setErrorInput('Введите номер телефона одного из операторов мобильной связи РФ')
        }
    }

    const nextStepHandler = () => {
            navigate(`/sbp/payment/${order_id}`,)
    }

    if (error) {
        return <UnknownError/>
    }

    return (
        <>
            {
                action === 'error-page' && <UnknownError/>
            }
            {
            cardStatus === 'created' ? <div className={style.Wrapper}>
                    <div className={s.Container}>
                        <div className={style.Header}>
                            <Logo/>
                        </div>
                        <h2>Введите номер телефона для оплаты платежа через СБП</h2>
                        <p>Номер телефона одного из операторов связи РФ, к которому привязан ваш аккаунт в СПБ с
                            которого будет осуществляться перевод</p>
                        <input type="tel" value={value} onChange={onChangeHandler}  placeholder={'+7 ___ ___-__-__'} onBlur={onBlurHandler}
                               className={`${s.InputDisabled} ${value.length >= 18 && s.InputGreen} ${errorInput && s.InputRed}`}/>
                        {errorInput ?
                            <span className={s.Error}>{errorInput}</span> : ''}
                        <button className={`${s.DisableBtn} ${!disableBtn && s.UnDisableBtn}`}
                                onClick={nextStepHandler}
                                disabled={disableBtn}>Продолжить
                        </button>
                    </div>
                </div>

                : navigate(`/sbp/payment/${order_id}`)
        }
            {
                action === 'error_dispute' && <TimeIsUp setAction={setAction} action={action}/>
            }


        </>

    )
}
