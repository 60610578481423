import s from "./Application.module.css";
import React, {ChangeEvent, useState, MouseEvent, KeyboardEvent, FocusEvent} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {postFormDara} from "../../http/http";
import {useParams} from "react-router-dom";
import InputMask from 'react-input-mask';
import {fetchPostDeclinedCardStatusTC} from "../../state/DataReducer";

type ApplicationType = {
    canselPaymentHandler?: () => void
    setAction: (tr: '' | 'time' | 'user' | 'payment' | 'support' | "successSupport") => void

}

export const Application = (props: ApplicationType) => {
    const card_number = useSelector((state: AppRootStateType) => state.userData.userData?.redirect_url);
    const dispatch: Function = useDispatch()

    const [amount1, setAmount1] = useState<number>(0);
    const [amount2, setAmount2] = useState<number>(0);
    const [date, setDate] = useState<string>('');
    const [card, setCard] = useState<string>('');
    const [file1, setFile1] = useState<File | undefined>();
    const [file2, setFile2] = useState<File | undefined>();
    const [maxLengthCard, setMaxLengthCard] = useState<number>(4)
    const [maxLengthCardError, setMaxLengthCardError] = useState<string | null>('')
    const [textAreaValue, setTextAreaValue] = useState('');

//Errors Value

    const [errorDate, setErrorDate] = useState<boolean | null>(false);
    const [errorCard, setErrorCard] = useState<boolean | null>(false);
    const [errorAmount, setErrorAmount] = useState<boolean | null>(false);
    const [errorFile, setErrorFile] = useState<boolean | null>(false);
    const [emailDirty, setEmailDirty] = useState<boolean>(false)
    const [cardDirty, setCardDirty] = useState<boolean>(false)
    const [AmountDirty, setAmountDirty] = useState<boolean>(false)

    const {order_id} = useParams<{ order_id: string }>()

    const [disableBtn, setDisableBtn] = useState(true);

    const onSubmit = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault()
        if (amount1 >= 1 && date !== '' && card !== '' && file1 !== undefined) {
            postFormDara(order_id || '', {
                payment_datetime: date,
                card_number: card,
                check_one: file1,
                check_one_amount: amount1,
                check_two: file2,
                check_two_amount: amount2,
                message: textAreaValue

            }).catch(e => console.log(e))
            // dispatch(fetchPostDeclinedCardStatusTC(order_id || '', 'dispute'))

            props.setAction('successSupport')
        }
        setErrorCard(true)
        setErrorDate(true)
        setErrorAmount(true)
        setErrorFile(true)
    }
    // useEffect(() => {
    //     if (errorDate || errorCard || errorAmount || errorFile) {
    //         setDisableBtn(false)
    //     } else {
    //         setDisableBtn(true)
    //     }
    // }, [errorDate, errorCard, errorAmount, errorFile]);
    const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
        if(card.length < 4){
            setErrorCard(true)
        }
        switch (e.target.name) {
            case 'data': {
                // console.log('dataHandler')
                setEmailDirty(true)
                break
            }
            case 'card': {
                setCardDirty(true)
                break
            }
            case 'amount': {
                setAmountDirty(true)
                break
            }
        }
    }
    const onKeyPressDataHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        setErrorDate(null)
    }
    const onKeyPressCardHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        setErrorCard(null)

    }
    const onKeyPressFileHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        setErrorFile(null)
    }
    const onKeyPressAmountHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        setErrorAmount(null)
    }

    const onChangeTextAreaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setTextAreaValue(e.currentTarget.value)

    }
    const uploadHandler = (e: ChangeEvent<HTMLInputElement>) => {
        setFile1(e.target.files?.[0])
    }
    const uploadHandler2 = (e: ChangeEvent<HTMLInputElement>) => {
        setFile2(e.target.files?.[0])
    }

    function refreshPage() {
        window.location.reload();
    }
    const [drag, setDrag] = useState(false)

    const dragStartHandler = (e:any) => {
        e.preventDefault()
        setDrag(true)
    }
    const dragLeaveHandler = (e:any) => {
        e.preventDefault()
        setDrag(false)
    }
    const onDropHandler = (e:any) => {
        console.log(e.target)
        e.preventDefault()
        setFile1(e.dataTransfer.files?.[0])
    }
    const onDropHandler2 = (e:any) => {
        console.log(e.target)
        e.preventDefault()
        setFile2(e.dataTransfer.files?.[0])
    }
    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <h2>Заявка на рассмотрение спорной ситуации</h2>
                <p>Заполните все обязательные поля</p>
                <form action="" className={s.Wr}>
                    <div className={s.WrapperColumns}>
                        <div className={s.Column1}>
                            <div className={s.Field1}>
                                <div className={s.Desc}>
                                    <p>Дата и время платежа по чеку</p>
                                    <p>Обязательно</p>
                                </div>
                                <InputMask mask={"99/99/9999 99:99"}
                                           onBlur={(e) => onBlurHandler(e)}
                                           name={'data'}
                                           onKeyPress={onKeyPressDataHandler}
                                           className={`${s.Input} ${errorDate && s.ErrorInput}`}
                                           value={date}
                                           required
                                           min="1997-01-01"
                                           max="2030-12-31"
                                           onChange={(e) => {
                                               const currentYear = new Date().getFullYear()
                                               setDisableBtn(false)
                                               let value = e.currentTarget.value;
                                               const [date, time] = value?.split(' ') || []
                                               let [day, month, year] = date?.split('/') || []
                                               let [hour, minutes] = time?.split(':') || []
                                               if (e.currentTarget.value === '') {
                                                   setErrorDate(true)
                                               }
                                               if (+day[0] > 3) {
                                                   day = '3' + day[1]
                                               }
                                               if (day[0] === '3' && +day[1] > 1) {
                                                   day = '31'
                                               }
                                               if (month === '02' && +day > 29) {
                                                   day = '29'
                                               }
                                               if (+month > 12) {
                                                   month = '12'
                                               }
                                               if (+month === 0) {
                                                   month = '01'
                                               }
                                               if (+year > currentYear) {
                                                   year = currentYear.toString()
                                               }
                                               if (+hour > 23) {
                                                   hour = '23'
                                               }
                                               if (+minutes > 59) {
                                                   minutes = '59'
                                               }
                                               setDate(`${day}/${month}/${year} ${hour}:${minutes}`)
                                           }}
                                           placeholder='ДД/MM/ГГ ЧЧ:ММ по МСК'
                                />

                            </div>
                            <div className={s.Field1}>
                                <div className={s.Desc}>
                                    <p>Чек №1</p>
                                    <p>Обязательно</p>
                                </div>
                                <div className={`${s.ContainerField} ${errorFile && !file1 && s.ErrorFieldWrapper} `}
                                     onDragStart={(e) => dragStartHandler(e)}
                                     onDragLeave={(e) => dragLeaveHandler(e)}
                                     onDragOver={(e) => dragStartHandler(e)}
                                     onDrop={(e) => onDropHandler(e)}
                                    >
                                    <p className={s.Formats}> JPG, PNG, WEBP до 20мб</p>
                                    <input type="file" id={'file1'} name={'file1'}
                                           onChange={uploadHandler}
                                           required
                                           onKeyPress={onKeyPressFileHandler}
                                           className={s.DefinitelyInput}/>
                                    <label className={s.File} htmlFor={'file1'}>
                                        Прикрепить файл
                                    </label>
                                </div>
                                {file1 ? <span className={s.FileAdded}>Файл выбран</span> : ''}

                            </div>
                            <div className={s.Field1}>
                                <div className={s.Desc}>
                                    <p>Сумма по чеку №1</p>
                                    <p>Обязательно</p>
                                </div>
                                <div className={`${s.InputWr} ${errorAmount && s.ErrorInput}`}>
                                    <input type="number"
                                           minLength={1}
                                           // value={amount1}
                                           value={amount1 === 0 ? '' : amount1}
                                           name={'amount'}
                                           onBlur={(e) => onBlurHandler(e)}
                                           onKeyPress={onKeyPressAmountHandler}
                                           onChange={(e) => {
                                               if(isNaN(e.currentTarget.valueAsNumber)){
                                                   setErrorAmount(true)
                                               }
                                               setAmount1(e.currentTarget.valueAsNumber)
                                               // console.log(e.currentTarget.valueAsNumber)
                                           }}
                                           className={s.Input2}
                                        // placeholder={'₽'}

                                    />
                                    ₽
                                </div>

                            </div>
                        </div>
                        <div className={s.Column2}>
                            <div className={s.Field1}>
                                <div className={s.Desc}>
                                    <p>Карта получателя платежа</p>
                                    <p>Обязательно</p>
                                </div>
                                <div className={`${s.InputErrorWr1}  ${errorCard && s.ErrorInput}`}>
                                    <span>**** **** ****</span>
                                    <input type="text" placeholder={'____'}
                                           value={card}
                                           name={'card'}
                                           maxLength={maxLengthCard}
                                           required
                                           onBlur={(e) => onBlurHandler(e)}
                                           onKeyPress={onKeyPressCardHandler}
                                           onChange={(e) => {
                                               // const hidden = (str:string, count = 4) => str.replace(/.*?(?=.{0,4}$)/, Array(count + 1).join('*'));
                                               const validValue = e.target.value = e.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
                                               setCard(validValue)
                                               if (e.target.value.length > 4) {
                                                   return setMaxLengthCardError('неверный номер карты')
                                               }
                                               // if (e.target.value.length <= 4) {
                                               //     setMaxLengthCardError(null)
                                               // }
                                           }}
                                           className={`${s.InputCstom}`}
                                    />
                                </div>

                                {/*{*/}
                                {/*    maxLengthCardError ? <span>{maxLengthCardError}</span> : ''*/}
                                {/*}*/}
                            </div>
                            <div className={s.Field1}>
                                <div className={s.Desc}>
                                    <p>Чек №2</p>
                                    <p className={s.NotObligatory}>Необязательно</p>
                                </div>
                                <div className={`${s.ContainerField} ${errorFile && !file1 && s.ErrorFieldWrapper} `}
                                     onDragStart={(e) => dragStartHandler(e)}
                                     onDragLeave={(e) => dragLeaveHandler(e)}
                                     onDragOver={(e) => dragStartHandler(e)}
                                     onDrop={(e) => onDropHandler2(e)}
                                >
                                    <p className={s.Formats}> JPG, PNG, WEBP до 20мб</p>
                                    <input type="file" id={'file2'}
                                           onChange={uploadHandler2}
                                           name={'file2'}
                                           className={s.DefinitelyInput}/>
                                    <label className={s.File} htmlFor={'file2'}>
                                        Прикрепить файл
                                    </label>
                                </div>
                                {file2 ? <span className={s.FileAdded}>Файл выбран</span> : ''}
                            </div>
                            <div className={s.Field1}>
                                <div className={s.Desc}>
                                    <p>Сумма по чеку №2</p>
                                    <p className={s.NotObligatory}>Необязательно</p>
                                </div>
                                <div className={s.InputWr}>
                                    <input type="number"
                                           value={amount2 === 0 ? '' : amount2}
                                           onChange={(e) => {

                                               setAmount2(e.currentTarget.valueAsNumber)
                                           }}
                                           className={s.Input2}
                                        // placeholder={'₽'}
                                    />
                                    ₽
                                </div>

                            </div>

                        </div>
                    </div>
                    <div className={s.WrapperTextArea}>
                        <div>
                            <span>Сообщение</span>
                            <span>Необязательно</span>
                        </div>
                        <textarea className={s.TextArea} placeholder={'Любые другие важные детали...'}
                                  value={textAreaValue}
                                  onChange={onChangeTextAreaHandler}
                        />
                    </div>
                    <div className={s.WrapperBtns}>
                        <button className={s.ReturnBtn}>
                            <a href={card_number}>Вернуться на сайт магазина</a>
                        </button>
                        <button className={`${s.DisableBtn}`} onClick={onSubmit}>
                            Отправить заявку
                        </button>

                    </div>
                </form>
            </div>
        </div>
    )
}