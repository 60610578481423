import s from "./Modal.module.css";

import {fetchPostDeclinedCardStatusTC} from "../../state/DataReducer";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";

type ModalType = {
    returnToForm:boolean
    setReturnToForm:(tr:boolean) => void
    canselForm:boolean
    setCanselPayment:(tr:boolean) => void
    setAction:(tr:'' | 'time' | 'user') => void
    action?:'' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "cancelled" | "error_dispute" | "error-page" | "rejected"
}
export const Modal = (props:ModalType) => {
    const backToTransactionHandler = () => {
        return props.setReturnToForm(!props.returnToForm)
    }
    const dispatch: Function = useDispatch();
    const {order_id} = useParams<{ order_id: string }>();

    const canselTransactionHandler = () => {
        props.setAction('user')
        props.setReturnToForm(!props.returnToForm)
        dispatch(fetchPostDeclinedCardStatusTC(order_id || '', 'cancelled'))
        props.setCanselPayment(true)
    }




    return (
        <div className={s.Wrapper}>

            <div className={`${s.Container} ${props.returnToForm && s.SLideContainer}`}
                // className={props.returnToForm ? s.SLideContainer : s.Container}
            >
                <h2>
                    Подтвердите действие
                </h2>
                <p>Вы действительно хотите отменить платеж?</p>
                <button className={s.Cansel} onClick={canselTransactionHandler}>Отменить платеж</button>
                <button className={s.Back} onClick={backToTransactionHandler}>Вернуться назад</button>
            </div>
        </div>
    )
}
