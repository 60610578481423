import s from "../step1/Step1.module.css";
import st from "./Receiving.module.css";
import {ReactComponent as Logo} from "../../imgs/Logo.svg";
import {ReactComponent as Close} from "../../imgs/close.svg";
import {Card} from "../card/Card";
import {Timer} from "../timer/Timer";
import React, {useEffect} from "react";
import {Step1Type} from "../step1/Step1";
import {fetchCardDataTC, fetchCardStatusTC} from "../../state/DataReducer";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {AppRootStateType} from "../../state/store";

type ReceivingType = {
    canselPaymentHandler: () => void
    setIsCounting: (tr:boolean) => void
    isCounting:boolean
    setAction:(tr:'' | 'time' | 'user' | 'payment') => void
    // timeLeft: number
    // setTimeLeft: (timeLeft:any) => number
}
export const Receiving = (props:ReceivingType) => {
    const dispatch: Function = useDispatch();
    const {order_id} = useParams<{order_id: string}>()

    const cardStatus = useSelector((state: AppRootStateType) => state.userData.userData?.status);


    useEffect( () => {
        dispatch(fetchCardStatusTC(order_id || ''))
        const interval = setInterval(() =>{
           dispatch(fetchCardStatusTC(order_id || ''))
        }, 15000)
        return () =>  clearInterval(interval)
    }, [order_id]);
////////////////////////////////////////
    useEffect(() => {
        if(cardStatus === 'confirmed'){
            props.setAction('payment')
        }
    }, [cardStatus, props]);

  return(
      <div className={st.Wrapper}>
          <div className={s.Container}>
              <div className={st.Header}>
                  <Logo/>
                  <button className={s.CloseBtn} onClick={props.canselPaymentHandler}>
                      <Close/>
                  </button>
              </div>
              <h2>Запрос успешно принят</h2>
              <p className={s.Desc1}>Сделайте перевод суммы на указанную карту</p>
              <Card/>
              <div className={st.timer}>
                  <Timer setAction={props.setAction}
                         setIsCounting={props.setIsCounting}
                         isCounting={props.isCounting}
                  />
              </div>
                  <ul className={st.Loader}>
                      <li className={st.LightLi}></li>
                      <li className={st.LMediumLi}></li>
                      <li className={st.MediumLi}></li>
                      <li className={st.BlackLi}></li>
                      <li className={st.BlackLi}></li>
                      <li className={st.BlackLi}></li>
                      <li className={st.MediumLi}></li>
                      <li className={st.LMediumLi}></li>
                      <li className={st.LightLi}></li>
                  </ul>
              <p className={s.Desc2}>Ищем ваш перевод</p>
          </div>
      </div>
  )
}