import s from "./Timer.module.css";
import React, {useEffect, useState} from "react";
import {getPadTime} from "../helpers/getPadTime";
import {ReactComponent as Clock} from "../../imgs/clock.svg";
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";

type TimerType = {
    setIsCounting: (tr: boolean) => void
    isCounting: boolean
    setAction: (tr: '' | 'time' | 'user') => void

}

export const Timer = (props: TimerType) => {

    const expired_in = useSelector((state: AppRootStateType) => state.userData.userData?.expired_in);
    const expired_at = useSelector((state: AppRootStateType) => state.userData.userData?.expired_at);

    const getTimeLeft = (expired_at: number) => {
        const res = ( expired_at - Date.now() / 1000 )
        return res >= 0 ? res : 0
    }

    const [timeLeft, setTimeLeft] = useState<number>(getTimeLeft(expired_at) || 0);

    // console.log(timeLeft, expired_in, Date.now())
    const minutes = getPadTime(Math.floor(timeLeft / 60));

    const seconds = getPadTime(Math.floor(timeLeft - minutes * 60));



    useEffect(() => {
        if (timeLeft !== 0 || timeLeft >= 1) {
            // console.log(timeLeft)
            const interval = setInterval(() => {
                const nextTimeLeft = getTimeLeft(expired_at)
                setTimeLeft(nextTimeLeft >= 0 ? nextTimeLeft : 0)
            }, 1000)
            return () => {
                clearInterval(interval)
            }
        }
        if (minutes && seconds <= 0 && expired_at !== undefined) {
            props.setAction('time')
            props.setIsCounting(!props.isCounting)
        }

    }, [timeLeft, expired_in, minutes, seconds, props])


    return (
        <>{
            expired_in && <div className={s.Wrapper}>
                <Clock className={s.Clock}/>
                <div className={s.Time}>
                    <p>{minutes}</p>
                    <span>мин</span>
                </div>
                <div className={s.Time}>
                    <p>{seconds}</p>
                    <span>сек</span>
                </div>
            </div>
        }</>

    )
}