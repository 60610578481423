import s from "./SuccesApplication.module.css";
import {ReactComponent as Done} from "../../imgs/SuccessDone.svg";
import React from "react";
import {useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";

type RejectType = {
    setAction: (tr: '' | 'time' | 'user' | 'payment' | "support") => void
}
export const SuccessApplication = () => {

    const linkToSite = useSelector((state: AppRootStateType) => state.userData.userData?.redirect_url);
    const userData = useSelector((state: AppRootStateType) => state.userData.userData);

    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <div className={s.WrapperDone}>
                    <Done className={s.Done}/>
                </div>

                <h2>Заявка отправлена</h2>
                <p>Мы проверим все данные и ответим вам в ближайшее время</p>
            </div>
        </div>
    )
}