import s from "../rejectedPayment/RejectedPayment.module.css";
import {ReactComponent as RejCard} from "../../imgs/rejCard.svg";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {fetchCreateRequestToOpenDisputeTC, fetchPostDeclinedCardStatusTC} from "../../state/DataReducer";
import {AppRootStateType} from "../../state/store";
import {createRequestToOpenDIspute} from "../../http/http";

type RejectType = {
    setAction: (tr: '' | 'time' | 'user' | 'payment' | 'support') => void
    action?: '' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "error_dispute"
}

export const TimeIsUp = (props: RejectType) => {

    const dispatch: Function = useDispatch();
    const {order_id} = useParams<{ order_id: string }>();
    const status = useSelector((state: AppRootStateType) => state.userData.userData?.status);

    useEffect(() => {
        if(status === 'processing'){
            dispatch(fetchPostDeclinedCardStatusTC(order_id || '', 'auto_dispute'))
        }else{
            dispatch(fetchPostDeclinedCardStatusTC(order_id || '', 'cancelled'))
        }

    }, [order_id, dispatch])

    const alertFunction = () => {
        props.setAction('support')
    }

    const param = window.location.href.split('/').at(-1)


    const redirectToSupportHandler = () => {
        dispatch(fetchCreateRequestToOpenDisputeTC("user" || '', param || ''))
    }
    let linkToSite = useSelector((state: AppRootStateType) => state.userData.redirect_url);
    if(!linkToSite?.startsWith('http')){
        linkToSite = 'http://' + linkToSite
    }

    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <RejCard className={s.Card}/>
                <h2>Платеж не был получен</h2>
                <p className={s.Desc}>Время истекло</p>
                <button className={s.ReturnBtn}>
                    <a href={linkToSite} > Вернуться на сайт магазина</a>
                </button>
                <button className={s.FailedBtn} onClick={alertFunction}>
                    Я перевел, платеж не прошел!
                </button>
            </div>
        </div>
    )
}