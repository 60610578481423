import s from "./SbpCard.module.css";
import {ReactComponent as Copy} from "../../../imgs/copy.svg";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {ReactComponent as SbpLogo} from "./sbp.svg";
import {AppRootStateType} from "../../../state/store";

export const SbpCard = () => {
    const userdata = useSelector((state: AppRootStateType) => state.userData.userData);
    const bankCard = useSelector((state: AppRootStateType) => state.userData.userData?.bank);
    const tel = useSelector((state: AppRootStateType) => state.userData.userData?.phone);
    const name = useSelector((state: AppRootStateType) => state.userData.userData?.cardholder);
    const price = useSelector((state: AppRootStateType) => state.userData.userData?.rub_amount)

    const [state, setState] = useState<any>({
            value: userdata?.card_number,
            copied: false,
        }
    )

    const rub_amount: any = userdata?.rub_amount;

    const [bankCopied, setBankCopied] = useState<boolean>(false);
    const [telCopied, setTelCopied] = useState<boolean>(false);
    const [nameCopied, setNameCopied] = useState<boolean>(false);
    const [priceCopied, setPriceCopied] = useState<boolean>(false);

    const copyBank = async () => {
        await navigator.clipboard.writeText(bankCard);
        setTimeout(() => {

            setBankCopied(false)
        }, 1200)
        setBankCopied(true)
    }
    const copyTel = async () => {
        await navigator.clipboard.writeText(tel);
        setTimeout(() => {

            setTelCopied(false)
        }, 1200)
        setTelCopied(true)
    }
    const copyName = async () => {
        await navigator.clipboard.writeText(name);
        setTimeout(() => {

            setNameCopied(false)
        }, 1200)
        setNameCopied(true)
     }
    const copyPrice = async () => {
        const newPrice = price.toString()
        await navigator.clipboard.writeText(newPrice);
        setTimeout(() => {

            setPriceCopied(false)
        }, 1200)
        setPriceCopied(true)
    }

    // const tr = 'Mastercard' | 'Visa' | 'МИР' | 'UnionPay' | 'СБЕР'
    const cardNumber = userdata.card_number.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim()


    return (
        <div className={s.Wrapper}>
            <div className={s.bank}>
                <p className={s.Title}>Банк</p>
                <div className={s.DescData}>
                    <p>{bankCard}</p>
                    <span>
                        <Copy className={s.Copy} onClick={copyBank}/>
                    </span>
                    <span className={`${s.CopiedText} ${bankCopied && s.ActiveText}`}>
                        Скопировано
                    </span>
                </div>
            </div>
            <div className={s.tel}>
                <p className={s.Title}>Номер телефона</p>
                <div className={s.DescData}>
                    <p>{tel}</p>
                    <span>
                        <Copy className={s.Copy} onClick={copyTel}/>
                    </span>
                    <span className={`${s.CopiedText} ${telCopied && s.ActiveText}`}>
                        Скопировано
                    </span>
                </div>
            </div>
            <div className={s.Name}>
                <p className={s.Title}>Имя</p>
                <div className={s.DescData}>
                    <p>{name}</p>
                    <span>
                    <Copy className={s.Copy} onClick={copyName}/>
                    </span>
                    <span className={`${s.CopiedText} ${nameCopied && s.ActiveText}`}>
                        Скопировано
                    </span>
                </div>
            </div>
            <div className={s.Amount}>
                <p className={s.Title}>Сумма для перевода</p>
                <div className={s.DescData}>
                    <p>{price} ₽</p>
                    <span>
                        <Copy className={s.Copy} onClick={copyPrice}/>
                    </span>
                    <span className={`${s.CopiedText} ${priceCopied && s.ActiveText}`}>
                        Скопировано
                    </span>
                </div>
            </div>
            <SbpLogo className={s.SbpLogo}/>
        </div>
    )
}
