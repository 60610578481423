import st from "./SbpStep1.module.css";
import s from "../../step1/Step1.module.css";
import {ReactComponent as Close} from "../../../imgs/close.svg";
import {ReactComponent as Logo} from "../../../imgs/Logo.svg";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {AppRootStateType} from "../../../state/store";
import {Card} from "../../card/Card";
import {fetchPostDeclinedCardStatusTC} from "../../../state/DataReducer";
import {Timer} from "../../timer/Timer";
import {SbpCard} from "../cardSbp/SbpCard";

export type Step1Type = {
    canselPaymentHandler: () => void
    setIsCounting: (tr: boolean) => void
    isCounting: boolean
    setAction: (tr: '' | 'time' | 'user' | 'nextStep' | 'payment' | "support") => void
    action?: '' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "cancelled"
}

export const SbpStep1 = React.memo(function Step1(props: Step1Type) {
    const userdata = useSelector((state: AppRootStateType) => state.userData.userData);
    // console.log(userdata.card_number)

    const dispatch: Function = useDispatch();
    const {order_id} = useParams<{ order_id: string }>();
    const cardStatus = useSelector((state: AppRootStateType) => state.userData.userData?.status);

    const
        onCloseModal = () => {
            props.canselPaymentHandler()
        }

    const sentHandler = () => {
        props.setAction('nextStep')
        dispatch(fetchPostDeclinedCardStatusTC(order_id || '', 'processing'))
    }

    return (
        <div className={`${s.Wrapper} ${st.Wrapper}`}>
            <div className={s.Container}>
                <div className={s.Header}>
                    <Logo/>
                    <button className={s.CloseBtn} onClick={onCloseModal}>
                        <Close/>
                    </button>
                </div>
                <h2>Запрос успешно принят</h2>
                <p className={`${s.Desc1} ${st.Desc1}` }>Сделайте перевод суммы в СПБ по указанным ниже данным</p>
                <div className={s.WrapperCard}>
                    <SbpCard/>
                </div>
                <div className={`${s.Attention} ${st.Attention}`}>
                    <p>
                        <span>Внимание! </span>
                        Сверяйте банк и имя получателя платежа! Переводите точную сумму одним переводом,
                        в противном случае перевод не будет зачислен!
                    </p>
                </div>
                <Timer setAction={props.setAction}
                       setIsCounting={props.setIsCounting}
                       isCounting={props.isCounting}/>

                <p className={s.Desc2}>Если вы отправили средства - нажмите “Я отправил”</p>
                <button className={st.SendBtn}
                        onClick={sentHandler}>Я отправил
                </button>
            </div>
        </div>
    )
})