import React from 'react';
import './App.css';
import {WrapperStep} from "./components/step1/WrapperStep";
import {Route, Routes} from "react-router-dom"
import {UnknownError} from "./components/unknownError/UnknownError";
import {FirstAdditionalStep} from "./components/additionalSteps/firstAddirionalStep/FirstAdditionalStep";
import {Sbp} from "./components/sbp/Sbp";
import {SbpStepWrapper} from "./components/sbp/SbpStepWrapper";


function App() {

    return (
        <div className="App">
            <Routes>
                <Route path={"/p2p/:order_id"} element={<FirstAdditionalStep/>}/>
                <Route path={"p2p/payment/:order_id"} element={<WrapperStep/>}/>
                <Route path={"sbp/:order_id"} element={<Sbp/>}/>
                <Route path={"sbp/payment/:order_id"} element={<SbpStepWrapper/>}/>
                <Route path={"*"} element={<UnknownError/>}/>
            </Routes>
        </div>
    );
}

export default App;
