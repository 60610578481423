import s from "./Step1.module.css";
import {ReactComponent as Close} from "../../imgs/close.svg";
import {ReactComponent as Logo} from "../../imgs/Logo.svg";

import {Card} from "../card/Card";
import {Timer} from "../timer/Timer";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from "react-router-dom";
import {
    fetchPostDeclinedCardStatusTC, setCardErrorAC
} from "../../state/DataReducer";
import {AppRootStateType} from "../../state/store";


export type Step1Type = {
    canselPaymentHandler: () => void
    setIsCounting: (tr: boolean) => void
    isCounting: boolean
    setAction: (tr: '' | 'time' | 'user' | 'nextStep' | 'payment' | "support") => void
    action?: '' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "cancelled"
}

export const Step1 = React.memo(function Step1(props: Step1Type) {
    const [buttonState, setButtonState] = useState(false);
    const userdata = useSelector((state: AppRootStateType) => state.userData.userData);
    // console.log(userdata.card_number)

    const dispatch: Function = useDispatch();
    const {order_id} = useParams<{ order_id: string }>();
    const cardStatus = useSelector((state: AppRootStateType) => state.userData.userData?.status);

    // useEffect(() => {
    //    if(cardStatus === 'confirmed'){
    //        dispatch(setCardErrorAC('error'))
    //    }
    // }, [cardStatus]);

    // useEffect(() => {
    //     if (props.action === "nextStep") {
    //         dispatch(fetchPostCardStatusTC(order_id || ''))
    //         props.setAction('nextStep')
    //     }
    // }, [order_id])



        const
    onCloseModal = () => {
        props.canselPaymentHandler()
    }

    const sentHandler = () => {
        props.setAction('nextStep')
        dispatch(fetchPostDeclinedCardStatusTC(order_id || '', 'processing'))
    }

    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <div className={s.Header}>
                    <Logo/>
                    <button className={s.CloseBtn} onClick={onCloseModal}>
                        <Close/>
                    </button>
                </div>
                <h2>Запрос успешно принят</h2>
                <p className={s.Desc1}>Сделайте перевод суммы на указанную карту</p>
                <div className={s.WrapperCard}>
                    <Card/>
                </div>
                <div className={s.Attention}>
                    <p>
                        <span>Внимание! </span>
                        Переводите точную сумму одним переводом или он не будет зачислен!
                    </p>
                </div>
                {/*<Tiiem/>*/}
                <Timer setAction={props.setAction}
                       setIsCounting={props.setIsCounting}
                       isCounting={props.isCounting}
                    // timeLeft={props.timeLeft}
                    // setTimeLeft={props.setTimeLeft}
                />
                <p className={s.Desc2}>Если вы отправили средства - нажмите “Я отправил”</p>
                <button className={s.SendBtn}
                        onClick={sentHandler}>Я отправил
                </button>
            </div>
        </div>
    )
})