import s from './firstAdditionalStep1.module.css';
import {ReactComponent as Logo} from "../../../imgs/Logo.svg";
import React, {ChangeEvent, FocusEvent, useEffect, useState, KeyboardEvent} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {fetchCardDataTC} from "../../../state/DataReducer";
import {UnknownError} from "../../unknownError/UnknownError";
import {AppRootStateType} from "../../../state/store";
import {SecondAdditionalStep} from "../secondAdditionalStep/SecondAdditionalStep";
import {RejectedPayment} from "../../rejectedPayment/RejectedPayment";
import {TimeIsUp} from "../../timeIsUp/TimeIsUp";


export const FirstAdditionalStep = () => {
    const dispatch: Function = useDispatch<any>();
    const {order_id} = useParams<{ order_id: string }>();
    const cardStatus = useSelector((state: AppRootStateType) => state.userData.userData?.status);
    const expired_in = useSelector((state: AppRootStateType) => state.userData.userData?.expired_in);
    const typeData = useSelector((state: AppRootStateType) => state.userData.userData?.type);

    const navigate = useNavigate();
    const [action, setAction] = useState<'' | 'time' | 'user' | 'nextStep' | 'payment' | "support" | "successSupport" | "error_dispute" | "error-page" | "rejected">('');

    useEffect(() => {
        dispatch(fetchCardDataTC(order_id || ''))
    }, [order_id, dispatch]);

    useEffect(() => {

        switch (cardStatus) {
            case 'created':
                if(typeData !== 'p2p'){
                    setAction("error-page")
                    return
                }
                setAction('')
                break;
            case 'cancelled':
                if(typeData !== 'p2p'){
                    setAction("error-page")
                    return
                }
                if (expired_in > 0) {
                    setAction('user')
                } else {
                    setAction('time')
                }
                break;
            case "auto_dispute":
                if(typeData !== 'p2p'){
                    setAction("error-page")
                    return
                }
                setAction('error_dispute')
                break
            case "rejected":
                if(typeData !== 'p2p'){
                    setAction("error-page")
                    return
                }
                setAction('rejected')
                break
        }
    }, [expired_in, typeData, cardStatus]);

    const [condition, setCondition] = useState<'failed' | 'check'>('check')
    const [value, setValue] = useState('');
    const [disableBtn, setDisableBtn] = useState(true);
    const [errorInput, setErrorInput] = useState<boolean>(false);


    const error = useSelector((state: AppRootStateType) => state.userData.error);

    const isValid = (card: string) => {
        const arr = []
        for (let i = 0; i < card.length; i++) {
            if (i % 2 === 0) {
                if (+card[i] * 2 < 10) {
                    arr.push(+card[i] * 2);
                } else {
                    arr.push(+card[i] * 2 - 9);
                }
            } else {
                arr.push(parseInt(card[i], 10))
            }
        }
        return arr.reduce((prv, cur) => prv + cur) % 10 === 0;
    }

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        e.currentTarget.value = e.currentTarget.value.replace(/[^\d]/g, '').replace(/(\d{4})/g, '$1 ').trimEnd()
        setValue(e.currentTarget.value)
        setErrorInput(false)
        if (e.currentTarget.value.length > 18) {
            setDisableBtn(false)
        } else {
            setDisableBtn(true)
        }
    }

    const onBlurHandler = (e: FocusEvent<HTMLInputElement>) => {
        if (value.length > 19) {
            setErrorInput(false)
        } else if (value.length < 19) {
            setErrorInput(true)
        }
    }

    const nextStepHandler = () => {
        // console.log(isValid(value.replace(/\s+/g, '')))
        if (!isValid(value.replace(/\s+/g, ''))) {
            setCondition('failed')
        } else {
            navigate(`/p2p/payment/${order_id}`,)
        }
    }

    if (error) {
        return <UnknownError/>
    }

    const onKeyPressHandler = (e: KeyboardEvent<HTMLInputElement>) => {
        if (e.charCode === 13) {
            nextStepHandler()
        }
        if (value.length > 17) {
            setErrorInput(false)
        }
    }


    return (
        <>  {
            cardStatus === 'created' ?
                <>
                    {condition === 'check' ?
                        <div className={s.Wrapper}>
                            <div className={s.Container}>
                                <div className={s.Header}>
                                    <Logo/>
                                </div>
                                <h2>Введите номер вашей карты для оплаты платежа</h2>
                                <p>Номер карты, с которой вы будете осуществлять перевод стредств</p>
                                <input type="text" value={value} placeholder={'16-19 цифр'}
                                       onChange={(e) => onChangeHandler(e)}
                                       maxLength={24}
                                       onBlur={onBlurHandler}
                                       onKeyPress={onKeyPressHandler}
                                       className={`${s.InputDisabled} ${value.length > 18 && s.InputGreen} ${errorInput && s.InputRed}`}
                                />
                                {errorInput ?
                                    <span className={s.Error}>Номер карты должен состоять из 16-19 цифр</span> : ''}
                                <button className={`${s.DisableBtn} ${!disableBtn && s.UnDisableBtn}`}
                                        onClick={nextStepHandler}
                                        disabled={disableBtn}>Продолжить
                                </button>
                            </div>
                        </div>
                        : <SecondAdditionalStep setStatus={setCondition}/>
                    }
                </>
                : navigate(`/p2p/payment/${order_id}`)
        }
            {
                action === 'rejected' && <UnknownError/>
            }
            {
                action === 'error_dispute' &&  <TimeIsUp setAction={setAction} action={action}/>
            }
            {
                action === 'error-page' && <UnknownError/>
            }
        </>

    )
}
