import s from "./PaymentSuccess.module.css";
import {ReactComponent as Logo} from "../../imgs/Logo.svg";
import {ReactComponent as Close} from "../../imgs/close.svg";
import {ReactComponent as Done} from "../../imgs/done.svg";
import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {AppRootStateType} from "../../state/store";
import {fetchCardDataTC} from "../../state/DataReducer";
import {useParams} from "react-router-dom";

type RejectType = {
    setAction: (tr: '' | 'time' | 'user' | 'payment' | "support") => void
}
export const PaymentSuccess = () => {
    const dispatch: Function = useDispatch();

    const {order_id} = useParams<{ order_id: string }>()
    useEffect(() => {
        dispatch(fetchCardDataTC(order_id || ''))
    }, [order_id]);

    const linkToSite = useSelector((state: AppRootStateType) => state.userData.userData?.redirect_url);
    const userData = useSelector((state: AppRootStateType) => state.userData.userData)
    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <Done className={s.Done}/>
                <h2>Платеж успешно получен</h2>
                <div className={s.dataWrapper}>
                    <div className={s.dataBlock}>
                        <p>Дата и время</p>
                        <div>{userData?.payment_dt}</div>
                        {/*<div><span>24.12.2023</span> 16:57:21</div>*/}
                    </div>
                    {/*<div className={s.dataBlock}>*/}
                    {/*    <p>Номер карты отправителя</p>*/}
                    {/*    <div className={s.NumberOfCard}>{userData?.card_number}</div>*/}
                    {/*</div>*/}
                    <div className={s.dataBlock}>
                        <p>Идентификатор операции</p>
                        <div>{userData?.id}</div>
                    </div>
                    <div className={s.dataBlock}>
                        <p>Статус:</p>
                        <div className={s.Status}>Выполнено</div>
                    </div>
                    <div className={s.dataBlock}>
                        <p>Полученная сумма</p>
                        <div>{userData?.rub_amount} ₽</div>
                    </div>
                </div>
                <button className={s.Back}>
                    <a href={linkToSite}>
                        Вернуться на сайт магазина
                    </a>
                </button>
            </div>
        </div>
    )
}