import s from "../../rejectedPayment/RejectedPayment.module.css";
import {ReactComponent as RejCard} from "../../../imgs/rejCard.svg";
import React from "react";
import {ReactComponent as Logo} from "../../../imgs/Logo.svg";
import {ReactComponent as Close} from "../../../imgs/close.svg";

type SecondAddType = {
    setStatus: (tr: 'failed' | 'check') => void
}

export const SecondAdditionalStep = (props: SecondAddType) => {

    const backToAuthorization = () => {
        props.setStatus('check')
    }

    return (
        <div className={s.Wrapper}>
            <div className={s.Container}>
                <RejCard className={s.Card}/>
                <h2>Запрос отклонен</h2>
                <p className={s.Desc}>Пожалуйста,
                    проверьте номер карты или попробуйте указать данные другой карты.
                </p>
                <button className={s.ReturnBtn} onClick={backToAuthorization}>
                    Вернуться назад
                </button>
            </div>
        </div>
    )
}
